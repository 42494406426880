import { CustomerMaterialSheet, CustomerMaterialTube, CustomerMaterialType, fetchPost, formatCents, LibraryPart, OrderForCustomer, OrderPart, Part, Powder, TermsTypes } from "@oshcut/oshlib";
import { getTubeName } from "components/Catalog/TubeListView";
import { getPartSize } from "getPartSize";
import { joinMaterial } from "joinMaterials";
import { CartPart, ParsedOrder } from "types";

export type ItemToAdd = {
  orderPart: OrderPart
  libraryPart?: LibraryPart
  qty?: number
} | {
  orderPart?: OrderPart
  libraryPart: LibraryPart
  qty?: number
}

/**
 * Fetches parts for an order, parses them, and returns them as a list of CartPart objects.
 * Does not provide loading overlays or error messages.
 * @param order 
 * @param materialTypes 
 * @param materialSheets 
 * @param materialTubes 
 * @param powders 
 * @returns 
 */
export async function loadCartPartsForOrder(
  order: ParsedOrder<OrderForCustomer>,
  materialTypes: CustomerMaterialType[],
  materialSheets: CustomerMaterialSheet[],
  materialTubes: CustomerMaterialTube[],
  powders: Powder[],

): Promise<CartPart[]> {
  let [
    order_parts,
    parts,
    library_parts
  ] = await Promise.all([
    fetchPost('/api/v2/order_part/for_order', {
      order_guid: order.guid,
    }),
    fetchPost('/api/v2/part/for_order', {
      order_id: order.id,
    }),
    fetchPost('/api/v2/library_part/for_order', {
      order_id: order.id,
    }),
  ])

  let orderParts = order_parts.orderParts.filter(op => !op.deleted)

  let parsedParts = orderParts.map(op => {
    const part = parts?.find(p => p.id === op.part_id.replace('N-', 'P-'))
    const libraryPart = library_parts.library_parts.find(lp => lp.item.id === op.item_id)

    let size = ''
    if (part) {
      size = getPartSize(part).scaledFormatted
    }

    let joinedMaterial;
    let material = ''
    if (materialTypes && materialSheets && materialTubes) {
      if (part?.type === 'tube') {
        joinedMaterial = joinMaterial(
          materialTubes.find(m =>
            m.id === part.material_tube),
          materialTypes)
        if (joinedMaterial) {
          material = getTubeName(joinedMaterial) + ' ' + joinedMaterial.name
        }
      } else {
        joinedMaterial = joinMaterial(
          materialSheets.find(m =>
            m.id === part?.material_sheet),
          materialTypes)
        if (joinedMaterial) {
          material = `${joinedMaterial.sheet_name} ${joinedMaterial.name}`
        }
      }
    }

    let partPriceCents: number | null = 0
    if (order.status === 'saved') {
      partPriceCents = order.latest_quote?.parts?.filter(q => q.parentId === op.part_id)?.reduce((a, c) => a + c.priceEachCents * c.quotedQty / c.parentQty, 0) ?? 0
    } else {
      let isPart = op.part_id.indexOf('N-') === 0 || op.part_id.indexOf('P-') === 0
      if (isPart && op.price_cents === 0) {
        // Show - for any parts with a price of 0
        partPriceCents = null
      } else {
        partPriceCents = op.price_cents
      }
    }

    let priceString = '-'
    let totalPriceCents = 0
    let totalPriceString = '-'
    if (partPriceCents != null) {
      priceString = formatCents(partPriceCents)
      totalPriceCents = partPriceCents * op.qty
      totalPriceString = formatCents(totalPriceCents)
    }

    let finishing = '';
    let grainDirection: 'horizontal' | 'vertical' | 'none' | undefined = part?.grain_direction
    let finishGrainDirection = part?.finish_grain_direction

    let finishingOption: '' | 'deburring' | 'brushed' | 'powder' | 'bead_blasting' | 'tumbling' | undefined;
    if (part?.has_powder) finishingOption = 'powder'
    else if (part?.has_bead_blasting) finishingOption = 'bead_blasting'
    else if (part?.has_tumbling) finishingOption = 'tumbling'
    else if (part?.finishing_type === 'none') finishingOption = ''
    else finishingOption = part?.finishing_type

    if (finishingOption === 'powder') {
      finishing = `Powder coat`
      let powder = powders?.find(p => p.id === part?.powder_id)
      if (powder)
        finishing += `: ${powder?.vendor_name}`
    } else if (finishingOption === 'bead_blasting') {
      finishing = `Bead blasted`
    } else if (finishingOption === 'tumbling') {
      finishing = `Tumbled`
    } else if (finishingOption === 'deburring') {
      finishing = `Deburred`
    } else if (finishingOption === 'brushed') {
      finishing = `Deburred`
      if (finishGrainDirection === 'horizontal') {
        finishing += `, horizontal brushed grain`
      } else if (finishGrainDirection === 'vertical') {
        finishing += `, vertical brushed grain`
      }
    } else if (joinedMaterial?.has_grain === 1 && grainDirection && grainDirection.toLowerCase() !== 'none') {
      finishing = `Surface Grain Direction: ${grainDirection}`
    }
    else {
      finishing = 'Edge as-cut (no deburring)'
    }

    let imgSrc = op.item_id
      ? `/api/v3/acc_item/thumbnail?id=${encodeURIComponent(op.item_id)}&size=small`
      : `/api/v1/part/png?part_id=${encodeURIComponent(op.part_id.replace('N-', 'P-'))}`

    let cartObj: CartPart = {
      id: op.part_id as Part['id'],
      imgSrc,
      name: op.name,
      qty: op.qty,
      size,
      material,
      finishing,
      priceCents: partPriceCents,
      priceString: priceString,
      totalCents: totalPriceCents,
      totalString: totalPriceString,
      orderPart: op,
      libraryPart,
      isLibraryPart: !!libraryPart?.item.customer_part_number,
      canDownload: !!part?.original_path
    }
    return cartObj
  })

  return parsedParts
}

// Cache formatters for each timezone
const formatters: Record<string, Intl.DateTimeFormat> = {}

/**
 * Gets the UTC offset, in hours, for a given timestamp in a given timezone.
 * @param timestamp The timestamp to get the UTC offset for.
 * @param timezone The timezone to get the UTC offset for. Defaults to 'America/Denver'.
 * @returns The UTC offset, in hours, for the given timestamp in the given timezone. For America/Denver, this will return either -6 or -7.
 */
export function getUTCOffset(timestamp: number | Date, timezone = 'America/Denver') {
  if (!formatters[timezone]) {
    formatters[timezone] = new Intl.DateTimeFormat('en', { timeZone: timezone, timeZoneName: 'shortOffset' })
  }
  const formatter = formatters[timezone]

  // Create a new Date object with the given timestamp
  const date = new Date(timestamp);

  // Get the UTC offset for the given timezone at the given timestamp
  const offset = formatter
    .formatToParts(date)
    .findLast(part => part.type === 'timeZoneName')
    ?.value
    ?.slice(3) // Remove the GMT part. What remains is the offset, e.g. '-6' or '+3'
    || '0'

  return parseInt(offset);
}

/**
 * Returns the difference between two dates as a number of whole days.
 * @param date1 The first date.
 * @param date2 The second date.
 * @param timezone The timezone to use for day boundaries. Defaults to 'UTC'.
 * @returns The difference between the two dates, `date2 - date1`, as a number of whole days.
 * @example
 * ```ts
 * // Example: regular dates, no times
 * const date1 = new Date('2024-06-01')
 * const date2 = new Date('2024-06-05')
 * const difference = differenceInDays(date1, date2) // 4
 * 
 * // Example: With times
 * const date1 = new Date('2024-06-01T12:00:00Z')
 * const date2 = new Date('2024-06-05T12:00:00Z')
 * const difference = differenceInDays(date1, date2) // 4
 * 
 * const date3 = new Date('2024-06-01T23:59:00Z')
 * const date4 = new Date('2024-06-02T00:01:00Z') // Only two minutes later
 * const difference2 = differenceInDays(date3, date4) // Still 1 day
 * 
 * // Example: With a timezone
 * const date5 = new Date('2024-06-01T23:59:00Z')
 * const date6 = new Date('2024-06-02T00:01:00Z') // Only two minutes later
 * const difference3 = differenceInDays(date5, date6, 'America/Denver') // 0. In Denver, these timestamps are the same day.
 * ```
 */
export function differenceInDays(date1: Date | number, date2: Date | number, timezone = 'UTC') {

  let utcOffsetMs1 = getUTCOffset(date1, timezone) * 60 * 60 * 1000
  let utcOffsetMs2 = getUTCOffset(date2, timezone) * 60 * 60 * 1000

  // Adjust for timezone offset
  let date1AdjustedTimestamp = new Date(new Date(date1).getTime() + utcOffsetMs1)
  let date2AdjustedTimestamp = new Date(new Date(date2).getTime() + utcOffsetMs2)

  // Set both dates to midnight
  date1AdjustedTimestamp.setHours(0, 0, 0, 0)
  date2AdjustedTimestamp.setHours(0, 0, 0, 0)

  // Calculate the difference in milliseconds
  let differenceMs = date2AdjustedTimestamp.getTime() - date1AdjustedTimestamp.getTime()

  // Convert to days (rounding just in case daylight savings does something tricky)
  return Math.round(differenceMs / (1000 * 60 * 60 * 24))
}


export function getTrackingURL(carrier: string, tracking: string) {
  switch (carrier) {
    case 'FedEx':
      return `https://www.fedex.com/fedextrack/?trknbr=${tracking}`
    case 'UPS':
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${tracking}`
    case 'USPS':
      return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking}`
    default:
      return null
  }
}

export function formatCityStateZip(city: string | undefined, state: string | undefined, zip: string | undefined) {
  return [city, state, zip].filter(Boolean).join(', ')
}

export function getLibraryPartName(libraryPart: LibraryPart, safeForFilename = false) {
  if (safeForFilename) {
    return `${libraryPart.item.customer_part_number.trim()}${libraryPart.item.customer_part_revision.trim() ? ` Rev ${libraryPart.item.customer_part_revision.trim()}` : ''}`
  } else {
    return `${libraryPart.item.customer_part_number.trim()}${libraryPart.item.customer_part_revision.trim() ? ` (Rev. ${libraryPart.item.customer_part_revision.trim()})` : ''}`
  }
}

export function humanReadableInvoiceTerms(terms: TermsTypes) {
  if (terms === 'due_on_receipt') {
    return 'Due on Receipt'
  }
  return terms.replace(/net/g, "Net ")
}

/**
 * Combines text with commas and 'and'
 * @param parts 
 */
export function combineParts(parts: string[], oxfordComma = true) {
  if (parts.length === 1) return parts[0]
  if (parts.length === 2) return parts[0] + ' and ' + parts[1]
  return parts.slice(0, parts.length - 1).join(', ') + (oxfordComma ? ', ' : '') + 'and ' + parts[parts.length - 1]
}
