import { LinkButton, PrimaryButton, useDropdown } from '@oshcut/components'
import { FormEvent, ReactNode, useContext, useRef, useState } from 'react'
import './ForgotPasswordForm.scss'
import { useAlert } from 'hooks/useAlert'
import { SignInContext } from 'signInContext'
import { fetchPost, useLocalStorageString } from '@oshcut/oshlib'
import config from '../config' 

const ForgotPasswordForm = () => {

  const { dispatch } = useContext(SignInContext)

  const [accountType, setAccountType] = useLocalStorageString<'personal' | 'company'>('customer_signin_account_type', 'personal')
  function handleAccountTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setAccountType(e.target.value as 'personal' | 'company')
  }
  
  const isCompanyAccountReset = config.features.companyAccounts && accountType === 'company'
  
  const [email, setEmail] = useState('')
  const [companyId, setCompanyId] = useState('')
  const refCompanyIdWhatsThis = useRef<HTMLButtonElement>(null)

  const {
    Dropdown: CompanyIdWhatsThisDropdown,
    openDropdown: openCompanyIdWhatsThisDropdown
  } = useDropdown(refCompanyIdWhatsThis)
  
  const [error, setError] = useState<ReactNode>('')
  const [isWorking, setIsWorking] = useState(false)

  const [alertContent, showAlert] = useAlert()

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)))
    {
      setError('Email not valid')
      return
    }
    
    if(isCompanyAccountReset && companyId === '') {
      setError('Company ID is required')
      return
    }

    setIsWorking(true)
    try {
      await fetchPost('/api/v1/customer/password/forgot', isCompanyAccountReset ? { email: email, companyId } : { email })

      showAlert('Email Sent', <div>We sent an email to <b>{email}</b> with instructions for resetting your password. Please check your email to continue.</div>)
      dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    } catch (ex) {
      if(isCompanyAccountReset) {
        setError(<span>An error occurred when trying to send a reset email to <b>{email}</b> and company ID <b>{companyId}</b>. Please try again.</span>)
      } else {
        setError(<span>An error occurred when trying to send a reset email to <b>{email}</b>. Please try again.</span>)
      }
    } finally {
      setIsWorking(false)
    }
  }

  function handleBack() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    dispatch({ type: 'ACTION_OPEN_SIGNIN_DIALOG' })
  }

  function handleCompanyIdWhatsThis() {
    openCompanyIdWhatsThisDropdown()
  }
  
  return (
    <form className='ForgotPasswordForm' onSubmit={handleSubmit}>

      <div className="message">
        <p>
          Please provide your email address below and we’ll send you a link to reset your password.
        </p>
      </div>

      {config.features.companyAccounts &&
        <div className='inputGroup'>
          <label htmlFor='accountType-select' className='label'>
            <span>Account Type</span>
          </label>
          <select id='accountType-select' value={accountType} onChange={handleAccountTypeChange}>
            <option value='personal'>Personal</option>
            <option value='company'>Company</option>
          </select>
        </div>
      }
      
      {isCompanyAccountReset && <div className='inputGroup'>
        <div className='label'>
          <span>Company ID</span>
          <LinkButton ref={refCompanyIdWhatsThis} type={'button'} tabIndex={-1} onClick={handleCompanyIdWhatsThis}>What's this?</LinkButton>
        </div>
        <input
          autoFocus
          name='company_id'
          type='text'
          value={companyId}
          onChange={(e) => setCompanyId(e.target.value)}
        />
      </div>}

      <div className="inputGroup">
        <div className="label"><span>Email</span></div>
        <input
          autoFocus
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      {error ? <div className="error">
        {error}
      </div> : null}

      <div className="submit">
        <PrimaryButton type="submit" disabled={isWorking}>Send Reset Link</PrimaryButton>
      </div>

      <LinkButton type={'button'} tabIndex={-1} onClick={handleBack}>
        Back
      </LinkButton>
      
      <CompanyIdWhatsThisDropdown>
        <div className="whatsThis">
          <div>The Company ID is the unique identifier for your company that was assigned when your company account was created with OSH Cut.</div>
          
          <div>If you do not know your Company ID, please contact your company's OSH Cut account administrator.</div>
        </div>
      </CompanyIdWhatsThisDropdown>

      {alertContent}
    </form>
  )
}

export default ForgotPasswordForm