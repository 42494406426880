import { LinkButton, LoadingSpinner, PrimaryButton, useDropdown } from '@oshcut/components'
import { CustomerUser, fetchPost, useLocalStorageString } from '@oshcut/oshlib'
import md5 from 'md5'
import { MouseEvent, useContext, useRef, useState } from 'react'
import { getRecaptchaToken } from './getRecaptchaToken'
import './SignInForm.scss'
import { SignInContext } from 'signInContext'
import { getErrorText } from './InvoicesView/OpenInvoices'
import config from '../config'

const SignInForm = () => {

  const { state, dispatch } = useContext(SignInContext)

  const [accountType, setAccountType] = useLocalStorageString<'personal' | 'company'>('customer_signin_account_type', 'personal')
  function handleAccountTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setAccountType(e.target.value as 'personal' | 'company')
  }

  const [signingIn, setSigningIn] = useState(false)

  const refCompanyIdWhatsThis = useRef<HTMLButtonElement>(null)

  const {
    Dropdown: CompanyIdWhatsThisDropdown,
    openDropdown: openCompanyIdWhatsThisDropdown
  } = useDropdown(refCompanyIdWhatsThis)
  const [companyID, setCompanyID] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function handleForgotPassword(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    dispatch({ type: 'ACTION_FORGOT_PASSWORD' })
  }

  const [error, setError] = useState<string>()

  async function handleSignIn(e: React.FormEvent) {
    e.preventDefault()
    if (signingIn) return

    if (!email || !password) {
      return setError('Please enter email and password.')
    }

    setSigningIn(true)
    const recaptchaToken = await getRecaptchaToken('login')

    try {
      const result = await fetchPost('/api/v3/customer_user/login', {
        username: email,
        password: md5(password),
        company_login_id: accountType === 'company' ? companyID : undefined,
        recaptcha_token: recaptchaToken
      })

      dispatch({ type: 'ACTION_SET_CUSTOMER_USER', customerUser: result })
      dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
      state.onSignInSuccess?.(result)
    } catch (ex) {
      setError(getErrorText(ex))
    } finally {
      setSigningIn(false)
    }
  }

  function moveToSignUp() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    dispatch({ type: 'ACTION_OPEN_SIGNUP_DIALOG' })
  }

  function handleCompanyIdWhatsThis() {
    openCompanyIdWhatsThisDropdown()
  }

  return (
    <form className='SignInForm' onSubmit={handleSignIn}>

      {config.features.companyAccounts &&
        <div className="inputGroup">
          <label htmlFor='accountType-select' className="label">
            <span>Account Type</span>
            {/* <LinkButton tabIndex={-1}>What's this?</LinkButton> */}
          </label>
          <select id='accountType-select' value={accountType} onChange={handleAccountTypeChange}>
            <option value="personal">Personal</option>
            <option value="company">Company</option>
          </select>
        </div>
      }

      {accountType === 'company' ? <div className="inputGroup inputGroup-company" >
        <label htmlFor='company_id-input' className="label">
          <span>Company ID</span>
          <LinkButton ref={refCompanyIdWhatsThis} type={'button'} tabIndex={-1} onClick={handleCompanyIdWhatsThis}>What's this?</LinkButton>
        </label>
        <input
          id="company_id-input"
          name="company_id"
          type="text"
          value={companyID}
          onChange={(e) => setCompanyID(e.target.value)}
          // Don't use autoComplete here, since there's no standard for our custom company_id field
        />
      </div> : null}

      <div className="inputGroup">
        <label htmlFor='email-input' className="label"><span>Email</span></label>
        <input
          autoFocus
          id="email-input"
          name='username' // Must use name="username" if you want browser password autofill to work
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
      </div>

      <div className="inputGroup">
        <label htmlFor='password-input' className="label">
          <span>Password</span>
          <LinkButton type={'button'} tabIndex={-1} onClick={handleForgotPassword}>Forgot password?</LinkButton>
        </label>
        <input
          id="password-input"
          name='password'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
      </div>

      {error ? <div className="error">
        {error}
      </div> : null}

      <div className="submit">
        <PrimaryButton type="submit" disabled={signingIn}>{signingIn ? <LoadingSpinner /> : 'Sign In'}</PrimaryButton>
      </div>

      <div className="forgotPassword">
        <span>New to OSH Cut?</span>&nbsp;
        <div className='link' onClick={moveToSignUp}>Create an Account</div>
      </div>

      <CompanyIdWhatsThisDropdown>
        <div className="whatsThis">
          <div>The Company ID is the unique identifier for your company that was assigned when your company account was created with OSH Cut.</div>

          <div>If you do not know your Company ID, please contact your company's OSH Cut account administrator.</div>
        </div>
      </CompanyIdWhatsThisDropdown>
    </form>
  )
}

export default SignInForm