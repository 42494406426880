import { useDropdown } from '@oshcut/components'
import React, { ReactNode, useMemo, useRef } from 'react'
import { BsBoxArrowRight, BsBoxFill } from 'react-icons/bs'
import { FaCartShopping, FaCircleUser, FaCreditCard, FaFileInvoiceDollar, FaGear, FaSackDollar } from 'react-icons/fa6'
import { MdBookmarks } from 'react-icons/md'
import { Link, useHistory } from 'react-router-dom'
import { DispatchFunction } from 'reducer'
import { StateType } from 'types'
import { useSignIn } from 'useSignIn'
import { LinkAnimator } from './LinkAnimator'
import './css/UserButton.scss'
import { useClientWidth } from 'hooks/useClientWidth'

type UserButtonProps = {
  state: StateType
  dispatch: DispatchFunction
}

const UserButton = ({ state, dispatch }: UserButtonProps) => {

  const mainButtonRef = useRef<HTMLButtonElement>(null)
  const { Dropdown, openDropdown, closeDropdown } = useDropdown(mainButtonRef)

  const history = useHistory()

  const { signOut, customer, customerUser, isCompanyAdmin } = useSignIn()

  const clientWidth = useClientWidth()

  async function handleLogout() {
    signOut()
    sessionStorage.removeItem('orderId')
    sessionStorage.removeItem('submittedOrder')
    history.push('/cart')
    dispatch({ type: 'ACTION_CLEAR_CART' })
    closeDropdown()
  }

  const avatarContent = useMemo<ReactNode>(() => {
    let initials = '';
    if (customerUser?.first_name) {
      initials += customerUser.first_name[0]
    }
    if (customerUser?.last_name) {
      initials += customerUser.last_name[0]
    }
    return initials.toUpperCase();
  }, [customerUser])

  return (
    <div className="UserButton">

      <button ref={mainButtonRef} onClick={openDropdown} style={{ position: 'relative' }}>
        <div className="userAvatar">{avatarContent}</div>
        {clientWidth > 600 ? (
          <div className="userDetails">
            <p>{`${customerUser?.first_name} ${customerUser?.last_name}`}</p>
            {customer?.company && <p className='userRole'>{customer.company}</p>}
          </div>
        ) : null}
        <LinkAnimator message={state.linkAnimatorMessages.account} />
      </button>

      <Dropdown>
        <Link onClick={closeDropdown} to="/account/quotes-orders"><BsBoxFill />Orders</Link>
        <Link onClick={closeDropdown} to="/account/saved-carts"><FaCartShopping />Saved Carts</Link>
        <Link onClick={closeDropdown} to="/account/library"><MdBookmarks />Part Library</Link>
        {!!customer?.use_invoices && <Link onClick={closeDropdown} to="/account/invoices"><FaFileInvoiceDollar />Invoices</Link>}
        <Link onClick={closeDropdown} to="/account/payments"><FaSackDollar />Payments</Link>
        {!!customer?.ff_checkout_v2 && <Link onClick={closeDropdown} to='/account/payment_methods'><FaCreditCard />Payment Methods</Link>}
        {isCompanyAdmin && <Link onClick={closeDropdown} to='/account/users'><FaCircleUser />Users</Link>}
        <Link onClick={closeDropdown} to="/account/settings"><FaGear />Settings</Link>
        <div className='logout' onClick={handleLogout}><BsBoxArrowRight />Sign Out</div>
      </Dropdown>

    </div>
  )
}

export default UserButton