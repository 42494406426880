/* Common config items */
const config = {
  basename: import.meta.env.VITE_BASENAME,
  appVersion: __APP_VERSION__, // See custom.d.ts and vite.config.ts
  staticAssets: import.meta.env.VITE_STATIC_ASSETS || '',
  recaptchaKey: import.meta.env.VITE_RECAPTCHA_KEY,
  features: {
    sendAsQuote: import.meta.env.VITE_FEATURE_SEND_AS_QUOTE === 'true',
    companyAccounts: import.meta.env.VITE_FEATURE_COMPANY_ACCOUNTS === 'true',
  }
}

export default config